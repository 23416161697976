/* eslint-disable prettier/prettier */

import { useCallback, useEffect, useState } from "react";

export default function useTabSearch(categories) {
	const [tabs, setTabs] = useState([])
	const [activeTab, setActiveTab] = useState(categories[0]);

	const handleSelectTab = useCallback((tab) => {
		if (JSON.stringify(tab) === JSON.stringify(activeTab)) {
			return;
		}

		setActiveTab(tab)
	}, [activeTab])

	useEffect(() => {
		setTabs(categories)
		setActiveTab(categories[0])
	}, [categories])

	return {
		tabs,
		activeTab,
		handleSelectTab,
	};
}
