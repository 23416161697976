import React, { useState, useMemo, useEffect } from "react";
import { FaArrowDown } from "react-icons/fa";
import Layout from "../../components/layout";
import CircleLoading from "../../components/circle-loading";
import queryMenu from "../../graphql/menu";
import { formatGQLQuery } from "../../utils";
import {
  articleSearchByTitle,
  popularArticles as popularArticlesQuery,
} from "../../graphql/article";
import useFetch from "../../hooks/useFetch";
import { allCategories } from "../../graphql/category";
import TabSearch from "../../components/tab-search";
import useTabSearch from "../../hooks/useTabSearch";
import Item from "../../components/common/item";
import PopularArticle from "../../components/popular-article";
import Button from "../../components/common/button";
import contactAndAddress from "../../graphql/footer";

export default function Search(props) {
  const {
    menu,
    query: queryDefault,
    categories,
    popularArticles,
    contact = {},
    address = {},
  } = props.serverData;
  const { tabs, activeTab, handleSelectTab } = useTabSearch(categories);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(true);

  const query = useMemo(() => {
    if (queryDefault || queryDefault.q) {
      return queryDefault.q;
    }
    return null;
  }, [queryDefault]);

  const reset = () => {
    setPage(1);
    setResults([]);
    setShowLoadMore(true);
  };

  const slug = useMemo(() => {
    return activeTab?.attributes?.slug || "";
  }, [activeTab]);

  const onLoadMore = () => {
    setPage(page + 1);
  };

  const bodyRequest = useMemo(() => {
    return JSON.stringify({
      query: formatGQLQuery(
        [articleSearchByTitle],
        [
          { key: "$articleSearchByTitle", type: "String!" },
          { key: "$articleSearchByTitleCategory", type: "String!" },
          { key: "$articleSearchByTitlePage", type: "Int!" },
          { key: "$articleSearchByTitlePageSize", type: "Int!" },
        ]
      ),
      variables: {
        articleSearchByTitle: query,
        articleSearchByTitleCategory: slug,
        articleSearchByTitlePage: page,
        articleSearchByTitlePageSize: 10,
      },
    });
  }, [query, slug, page]);

  const {
    loading,
    data: response,
    error,
  } = useFetch(process.env.GRAPHQL_URL, bodyRequest, {
    dependencies: [page, slug],
  });

  useEffect(() => {
    const data = response?.articleSearchByTitle?.data;
    if (data && Array.isArray(data)) {
      if (data.length === 0) {
        setShowLoadMore(false);
      } else {
        setResults((prev) => {
          return [...prev, ...data];
        });
      }
    }
  }, [response]);

  const isShowMore = useMemo(() => {
    if (!error && !loading && showLoadMore && results.length > 10) {
      return true;
    } else {
      return false;
    }
  }, [loading, error, showLoadMore, results]);

  const onChangeTab = (tab) => {
    handleSelectTab(tab);
    reset();
  };

  return (
    <Layout menu={menu} contact={contact} address={address} {...props}>
      <main className="flex-1">
        <div className="container my-10">
          <div className="flex flex-col items-start gap-10 md:flex-row">
            <div className="w-full md:w-2/3">
              <TabSearch
                query={query}
                tabs={tabs}
                activeTab={activeTab}
                handleSelectTab={onChangeTab}
              />
              <Content
                query={query}
                isShowMore={isShowMore}
                results={results}
                loading={loading}
                error={error}
                onLoadMore={onLoadMore}
              />
            </div>
            <div className="w-full border-gray-300 md:w-1/3 md:border-l md:pl-10">
              <PopularArticle popularArticles={popularArticles} />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

const Content = ({
  query,
  loading,
  results,
  error,
  isShowMore,
  onLoadMore,
}) => {
  if (!query) {
    return null;
  }
  if (loading && results.length === 0) return <CircleLoading />;

  if (!results || results.length === 0 || error) {
    return (
      <div className="text-center">
        <p className="mt-4 text-gray-400">
          {error
            ? "Maaf, telah terjadi kesalahan internal"
            : "Kami tidak menemukan hasil. Mohon coba kata kunci yang berbeda."}
        </p>
      </div>
    );
  }
  return (
    <div className="my-4 w-full">
      <div className="mt-3">
        {results.map(({ attributes: item }, index) => {
          const url = item.url || `/detail/${item?.slug}`;
          const imageAlt =
            item.cover?.data?.attributes?.alternativeText ||
            item.cover?.data?.attributes?.caption ||
            item.title;
          let subtitleContent = item.description;
          if (Array.isArray(item?.blocks)) {
            const richText = Array(...item?.blocks).find(
              (block) => block?.["__typename"] === "ComponentSharedRichText"
            );
            subtitleContent = richText?.body;
          }
          const subtitle = React.createElement("div", {
            children: subtitleContent,
            className: "rich-text-content line-clamp-3 text-gray-500",
          });
          return (
            <Item
              key={`article-item-${index}`}
              containerClassName="my-6 pb-6 border-b border-gray-300 flex-row-reverse justify-end"
              image={item.cover?.data?.attributes?.url}
              imageAlt={imageAlt}
              label={new Date(item.date).toLocaleDateString("id-ID", {
                dateStyle: "medium",
              })}
              title={item.title}
              subtitle={subtitle}
              url={url}
            />
          );
        })}
      </div>
      {isShowMore && (
        <Button
          onClick={onLoadMore}
          className={`mx-auto mt-5 flex flex-row items-center`}
        >
          Muat lebih banyak <FaArrowDown className="ml-2" />
        </Button>
      )}
    </div>
  );
};

export async function getServerData({ query }) {
  const today = new Date();
  const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
  const isoThirtyDaysAgo = thirtyDaysAgo.toISOString().toString();
  
  try {
    const response = await fetch(process.env.GRAPHQL_URL_SSR, {
      cache: "no-cache",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: formatGQLQuery(
          [queryMenu, allCategories, popularArticlesQuery, contactAndAddress],
          [
            { key: "$popularArticlesLimit", type: "Int!" },
            { key: "$popularArticlesMaxDate", type: "DateTime!" }
          ]
        ),
        variables: {
          popularArticlesLimit: 5,
          popularArticlesMaxDate: isoThirtyDaysAgo,
        },
      }),
    });

    const { data } = await response.json();
    return {
      props: {
        menu: data?.menu?.data,
        categories: data?.allCategories?.data,
        popularArticles: data?.popularArticles?.data,
        query,
        contact: data?.contact?.data,
        address: data?.address?.data,
      },
      status: 200,
    };
  } catch (error) {
    return {
      status: 500,
    };
  }
}
