import React from "react";
import TitleGroup from "./common/title-group";
import { dateFormat, normalizeUrl } from "../utils";

const PopularArticle = ({ popularArticles }) => {
  return (
    <>
      <TitleGroup title="Populer" />
      {popularArticles?.map(({ attributes: item }, index) => {
        const url = item?.url || `/detail/${item.slug}`;
        const imageUrl = item.cover?.data?.attributes?.url;
        return (
          <a
            key={`popular-article-${index}`}
            href={url}
            className={`flex flex-row py-2 transition-all transition-200 hover:scale-105`}
          >
            {imageUrl && (
              <img
                className="h-16 w-20 object-cover"
                effect="blur"
                alt={
                  item.cover?.data?.attributes?.alternativeText ||
                  item.cover?.data?.attributes?.caption ||
                  item.title
                }
                src={normalizeUrl(imageUrl)}
              />
            )}
            <div className={` ${imageUrl ? "ml-3" : ""} line-clamp-4`}>
              <span className="mt-2 text-xs text-gray-400">
                {dateFormat(item.date)}
              </span>
              <div className="col-span-1 mr-2 pb-2 text-fontPrimary">
                {item.title}
              </div>
            </div>
          </a>
        );
      })}
    </>
  );
};

export default PopularArticle;
