import React from "react";

const TabSearch = (props) => {
  const { query, tabs, handleSelectTab, activeTab } = props;

  return (
    <div className="text-left">
      <div className="text-3xl font-semibold text-gray-400">
        Hasil untuk <span className="text-fontPrimary">{query}</span>
      </div>
      <div className="my-8 overflow-x-auto">
        <div className="relative z-10 flex w-max items-start gap-5 border-b border-gray-300 pb-5 md:w-full md:gap-8">
          {tabs?.map((tab, i) => (
            <div
              onClick={() => handleSelectTab(tab)}
              className={`group relative w-max cursor-pointer ${
                activeTab === tab
                  ? "font-semibold text-fontPrimary"
                  : "text-gray-400"
              } transition-colors hover:text-fontPrimary`}
              key={tab.attributes.slug + i}
            >
              {tab.attributes.name}
              <div
                className={`absolute top-11 ${
                  activeTab === tab ? "block" : "hidden"
                } h-[1px] w-full bg-fontPrimary group-hover:block`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TabSearch;
